<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M12 9v3.75m-9.3 3.38a2.25 2.25 0 0 0 1.94 3.37h14.71a2.25 2.25 0 0 0 1.95-3.37L13.95 3.38a2.25 2.25 0 0 0-3.9 0L2.7 16.13zm9.3-.38h0v0h0v0z"
    />
  </svg>
</template>
